<template>
    <div>
        <v-toolbar
            dense
            tabs
            color="primary darken-2"
            dark
            class="page_title"
        >
            <v-toolbar-title class="pa-0 pr-0 mt-1">
                <v-layout row >
                    <v-flex xs10 sm11 text-xs-left>
                        <a href="javascript: history.go(-1)" class="pa-0">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-arrow-left headline"></i>
                            </v-btn>
                        </a>
                        Login
                    </v-flex>
                    <v-flex xs2 sm1 text-xs-right>
                        <router-link :to="$store.state.close_url" class="pa-0 text-xs-right">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-close headline"></i>
                            </v-btn>
                        </router-link>
                    </v-flex>
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container>
            <v-layout row>
                <v-flex xs12 md8 offset-md2 lg6 offset-lg3>
                    <v-slide-y-transition mode="out-in">
                        <div class="text-xs-center">
                            <div>
                                <p class="text-center text-dark pt-4" style="font-size: 14px;">Welcome to our financial educational platform which helps you learn and attain financial discipline.</p>
                            </div>
                            <v-card class="blue lighten-4" style="" >
                                <v-card-text style="font-size: 12px; ">
                                    <p class="dark--text subheading pa-0 ma-0 text-xs-center"><span> Login to have access to features that will help you manage your finances. </span></p>
                                </v-card-text>
                            </v-card>
                            <v-card color="white pa-3 " style="background-color: rgb(255, 255, 255)!important" >
                                <v-layout row>
                                    <v-flex xs12 md10 offset-md1>            

                                        <v-layout row>
                                            <v-flex xs2 sm1>
                                                <i class="teal--text fas fa-user-circle fa-2x mt-2"> </i>                            
                                            </v-flex>
                                            <v-flex xs10 sm10>
                                                <v-text-field class="pa-0"
                                                    id="username" v-model="username" ref="username"
                                                    :rules="[v => !!v || 'Username is required']"
                                                    label="Username"
                                                    single-line
                                                    color="teal"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs1 >
                                                <v-btn @click.stop="$store.commit('activateDialog', true); username_help = true; getLogInFieldHelp()" flat fab small outline color="black" class="my-3" style="height:20px; width: 20px" >
                                                    <i class="fas fa-question"> </i>                            
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>

                                        <v-layout row>
                                            <v-flex xs2 sm1>
                                                <i class="teal--text fas fa-lock fa-2x mt-2"> </i>                            
                                            </v-flex>
                                            <v-flex xs10 sm10>
                                                <v-text-field class="pa-0"
                                                    id="password" v-model="password" ref="password"
                                                    label="Enter Password"
                                                    :rules="[v => !!v || 'Your Password is required (at least 8 characters)']"
                                                    single-line
                                                    color="teal"
                                                    min="8"
                                                    :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                                    @click:append="() => (e1 = !e1)"
                                                    :type="e1 ? 'password' : 'text'"
                                                    counter
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs1 >
                                                <v-btn @click.stop="$store.commit('activateDialog', true); password_help = true; getLogInFieldHelp()" flat fab small outline color="black" class="my-3" style="height:20px; width: 20px" >
                                                    <i class="fas fa-question"> </i>                            
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <div class="text-xs-right pa-0 ma-0" >
                                    <router-link class="blue--text text-xs-right pa-0 ma-0" style="cursor: pointer" :to="{name: 'forgot_password'}" >Forgotten your username/password?</router-link>
                                </div>
                                <div  class=" ">
                                    <v-layout row wrap>
                                        <v-flex xs7 sm6 class="text-xs-center">            
                                            <v-btn small class="white--text elevation-6" round color="red " :to="{name: 'signup'}" >
                                                <span> Create Account</span>
                                            </v-btn>
                                        </v-flex>
                                        <v-flex xs5 sm6 class="text-xs-center">            
                                            <v-btn small class="white--text elevation-6" round color="teal darken-2" @click="logIn()">
                                                <span> Submit</span>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>

                                </div>
                            </v-card>
                        </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                e1: true,
                prevRoute: null,

                username_help: false,
                password_help: false,

                username: "",
                password: "",
            }
        },
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Log In"
            if(this.$store.state.isAuthenticated){
                this.$store.commit('setSnackBarMessage', "You are already logged in.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)    
                var navigation = this.$router
                // const toPath = '/'
                console.log(this.prevRoute)
                navigation.push("/")
            }
        },
        beforeRouteEnter(to, from, next){
            next(vm => {
                console.log("from", from.name, "to", to, "next", next)
                if (to.query.to){
                    vm.prevRoute = to.query.to
                }else{
                    vm.prevRoute = "/"
                }

            })
        },

        methods:{
            getLogInFieldHelp(){
                if (this.username_help){
                    this.username_help = false
                    this.$store.commit('setDialogTitle', "Your User Name")
                    this.$store.commit('setDialogMessage', "Here you are required to input your User Name.")
                    this.$store.commit('activateDialog', true)
                }else if (this.password_help){
                    this.password_help = false
                    this.$store.commit('setDialogTitle', "Your Password")
                    this.$store.commit('setDialogMessage', "You are required to input your account password. This password needs to be at least 8 characters long. It is also advisable for you to write down this password so in case u forget, you can know where to get it.")
                    this.$store.commit('activateDialog', true)
                }
            },
            async logIn(){
                axios.defaults.headers.common['Authorization'] = ""
                localStorage.removeItem('token')

                if(this.username.length < 4){
                    this.$store.commit('setSnackBarMessage', "User name is too short, user name needs to be at least 4 characters")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.username.focus()  // this causes a an auto fucos to the element
                }
                else if(this.password.length < 8){
                    this.$store.commit('setSnackBarMessage', "Password is too short, needs to be at least 8 characters")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.password.focus()  // this causes a an auto fucos to the element
                }
                else {
                    this.$store.commit('setIsLoading', true)
                    const formData = {
                        username: this.username,
                        password: this.password
                    }

                    await axios
                        .post('/api/v1/token/login/', formData)
                        .then(response => {
                            const token = response.data.auth_token
                            this.$store.commit('setToken', token)
                            axios.defaults.headers.common['Authorization'] = "Token " + token
                            localStorage.setItem('token', token)
                            var navigation = this.$router
                            this.$store.state.username = this.username
                            // const toPath = '/'
                            console.log(this.prevRoute)
                            if(this.$store.state.acceptedNjangiGroup){
                                const group_id = this.$store.state.acceptedNjangiGroupId
                                axios
                                    .post('/api/v1/njangi/accept/invitation/'+group_id+'/')
                                    .then(response => {
                                        this.$store.commit('setSnackBarMessage',"Welcome back "+this.username+" 😃")
                                        this.$store.commit('setSnackBarColor', "teal darken-4")
                                        this.$store.commit('activateSnackBar', true)
                                        if(response.data[0].id !== 0){
                                            let router = this.$router
                                            setTimeout(function(){
                                                router.push('/njangi/groups/detail/'+group_id+'/')
                                                this.$store.commit('setSnackBarMessage', response.data[0].text)
                                                this.$store.commit('setSnackBarColor', response.data[0].color)
                                                this.$store.commit('activateSnackBar', true)
                                            }, 4000)
                                        }
                                    })
                                    .catch(error => {
                                        if (error.response){
                                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                            this.$store.commit('setSnackBarColor', "red darken-4")
                                            this.$store.commit('activateSnackBar', true)
                                        }else{
                                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                            this.$store.commit('setSnackBarColor', "red darken-4")
                                            this.$store.commit('activateSnackBar', true)
                                        }
                                    })
                            }
                            else{
                                navigation.push(this.prevRoute)
                                this.$store.commit('setSnackBarMessage',"Welcome back "+this.username+" 😃")
                                this.$store.commit('setSnackBarColor', "teal darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                        .catch(error => {
                            if (error.response){
                                for (const property in error.response.data){
                                    this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                                    this.$store.commit('setSnackBarColor', "red darken-4")
                                    this.$store.commit('activateSnackBar', true)
                                }
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.$store.commit('setIsLoading', false)
                }

            },
        },
    }
</script>
